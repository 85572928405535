import {
  SUBSCRIBE_FORM_SET,
  SetSubscribeFormData,
  SetSubscribeFormDataPayload,
  SubscribeAction,
} from './actions';

export type SubscribeState = {
  subscribeFormData: SetSubscribeFormDataPayload
}

export const initialState: SubscribeState = {
  subscribeFormData: {
    email: {
      value: '',
      error: '',
    },
  },
};

const subscribeReducer = (
  state: SubscribeState = initialState,
  action: SubscribeAction,
): SubscribeState => {
  switch (action.type) {
    case SUBSCRIBE_FORM_SET:
      return {
        ...state,
        subscribeFormData: (
          action as SetSubscribeFormData
        ).payload,
      };
    default:
      return state;
  }
};

export default subscribeReducer;

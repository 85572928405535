export const SUBSCRIBE_FORM_SET = '[subscribe] form/set';
export type SetSubscribeFormDataPayload = {
  email: {
    value?: string;
    error?: string;
  }
}
export type SetSubscribeFormData = {
  type: typeof SUBSCRIBE_FORM_SET;
  payload: SetSubscribeFormDataPayload;
}
export const setSubscribeFormData = (
  data: SetSubscribeFormDataPayload,
): SetSubscribeFormData => ({
  type: SUBSCRIBE_FORM_SET,
  payload: data,
});

export type SubscribeAction = SetSubscribeFormData;
